import { Controller } from "@hotwired/stimulus";
import MicroModal from "micromodal";
import { initImageUploadEvent } from "../../../manage/shared/image_upload";
import { setEvent as sortEvnet } from "./item_sort";
const modalId = "test";

const modalCloseEnvet = () => {
  // モーダルでアイテムが選択されなかった場合は、追加した項目を削除する
  const elements = document.querySelectorAll(".current-modal-item");
  const item_id = elements[0].querySelector('input[name*="[item_id]"]').value;
  if (item_id == "") {
    elements[0].remove();
  }
};

// Connects to data-controller="manage-campaigns-select-item"
export default class extends Controller {
  static targets = ["item_id", "category_id", "customer_id"];

  // コンストラクタ
  connect() {
    console.debug("manage-campaigns-select-item");
    MicroModal.init();
    const elements = document.querySelectorAll(".js-sortable-item");
    elements.forEach((element) => {
      element.addEventListener(
        "cocoon:after-insert",
        this.setupModal.bind(this)
      );
    });
    document
      .getElementById("campaign_groups-association-insertion-point")
      .addEventListener("cocoon:after-insert", () => {
        initImageUploadEvent();
        const elements = document
          .getElementById("campaign_groups-association-insertion-point")
          .querySelectorAll(".js-sortable-item");
        elements.forEach((element) => {
          element.addEventListener(
            "cocoon:after-insert",
            this.setupModal.bind(this)
          );
        });
      });
    this.changeCategory();
    const elem = document.getElementById("campaign_customer_id");
    if (!!elem) {
      elem.addEventListener("change", this.changeCustomer);
    }

    sortEvnet();
    initImageUploadEvent();
  }

  // デストラクタ
  disconnect() {}

  setupModal(event) {
    this.openModal(event);
    this.search();
  }
  // モーダルを開く
  // モーダルを開いたアイテムグループがわかるように[current-modal-item]を付与する
  // グループ商品の項目を追加されるが、項目を非公開にしている。アイテムを選択したら非公開を解除する
  openModal(event) {
    const elements = document.querySelectorAll(".current-modal-item");
    elements.forEach((element) => {
      element.classList.remove("current-modal-item");
    });
    const elems = event.currentTarget.querySelectorAll(".sortable-item");
    const elem = elems[elems.length - 1];
    elem.style.display = "none";
    elem.classList.add("current-modal-item");
    MicroModal.show(modalId, {
      onClose: modalCloseEnvet,
    });
  }

  changeCustomer(event) {
    const elements = document.querySelectorAll(".sortable-item");
    elements.forEach((element) => {
      element.remove();
    });
    document.getElementById("customer_id").value = event.currentTarget.value;
  }

  changeCategory() {
    const elem = document.getElementById("category_id_category_id");
    elem.addEventListener("change", (event) => {
      this.search();
    });
  }

  async search() {
    const customer_id = document.getElementById("customer_id").value;
    const category_id = document.getElementById(
      "category_id_category_id"
    ).value;
    const response = await fetch(
      "/manage/campaigns/search_items?customer_id=" +
        customer_id +
        "&category_id=" +
        category_id
    );
    if (!response.ok) {
      alert("商品情報の取得に失敗しました。");
      return;
    }
    this.clear();

    const jsonData = await response.json();
    jsonData.forEach((item) => {
      this.add(item);
    });
    const elements = document.querySelectorAll("#search-result a");
    elements.forEach((element) => {
      element.addEventListener("click", this.select);
    });
  }

  add(item) {
    const target = document.getElementById("search-result");
    const renderCategoryName = (item) => {
      if (item.item_categories.length == 0) return "";
      return `<span class="tag">${item.item_categories[0]?.name || ""}</span>`;
    };
    const dom = `
<div class="card product-item">
  <a href="javascript: void(0);" data-item-id="${item.id}" data-item-name="${
      item.name
    }">
      <div class="p-3 d-flex direction-column">
          <div class="thumb"><img src="${item.image_url}"></div>
          <div class="text pt-3">
              <div class="c-tag d-flex mb-2">
                ${renderCategoryName(item)}
              </div>
              <h3 class="heading4 mb-1">${item.name}</h3>
              <p class="c-price mb-2">
                  <span class="price">${ !!item.campaign_price_with_tax ? Number(item.campaign_price_with_tax).toLocaleString() : "-" }</span>
                  <span class="price-unit">円（税込）/${item.unit} 〜</span>
              </p>
              <p class="description note-text mb-2 pt-2">${item.description}</p>
              <div class="view-detail btn size-m primary full">この商品を追加</div>
          </div>
      </div>
  </a>
</div>
`;
    // `
    // <div class="card product-item">
    //   <div class="p-3 d-flex direction-column">
    //     <img src="${item.image_url}">
    //     <div>ID: ${item.id}</div>
    //     <div>Name: ${item.name}<div>
    //     <div>Unit: ${item.unit}</div>
    //     <div>Price: ${item.campaign_price || "-"}</div>
    //     <div>Category ID: ${item.item_categories[0]?.id || "-"}</div>
    //     <div>Category Name: ${item.item_categories[0]?.name || "-"}</div>
    //     <div>Description: ${item.description}</div>
    //     <a href="javascript:void(0);" class="btn btn-primary" data-item-id="${
    //       item.id
    //     }" data-item-name="${item.name}" >この商品を追加</a>
    //   </div>
    // </div>
    // `;
    target.insertAdjacentHTML("beforeend", dom);
  }

  clear() {
    const target = document.getElementById("search-result");
    if (target.childElementCount > 0) {
      target.innerHTML = "";
    }
  }

  select(event) {
    const elem = document.querySelectorAll(".current-modal-item")[0];
    elem.querySelector('input[name*="[item_id]"]').value =
      event.currentTarget.dataset.itemId;
    const order = elem.parentNode.querySelectorAll(".sortable-item").length;
    elem.querySelector('input[name*="[order]"]').value = order - 1;
    elem.querySelector(".sortable-item-label").innerHTML =
      event.currentTarget.dataset.itemName;
    elem.style.display = null;
    MicroModal.close(modalId);
  }
}
