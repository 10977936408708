import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="manage-site-deliveries-form"
export default class extends Controller {
  // コンストラクタ
  connect() {
    console.debug("manage-site-deliveries-form");
    this.setEvent();
  }

  // デストラクタ
  disconnect() {}

  setEvent() {
    const priceCopyTriggers = document.querySelectorAll(".js-price-copy");

    priceCopyTriggers.forEach((trigger) =>
      trigger.addEventListener("click", this.priceCopy)
    );
  }

  priceCopy() {
    const price = this.parentNode.querySelector("input").value;
    if (price == "") return;
    const targets = this.closest(".form-table").querySelectorAll(
      ".gutter-wide input.full"
    );
    targets.forEach((target) => target.setAttribute("value", price));
  }
}
