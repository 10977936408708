import MicroModal from 'micromodal'
import { Controller } from "@hotwired/stimulus"

// ちょっと登録部分がイケてないけど、いったんこれでやっちゃう
export default class extends Controller {
  // コンストラクタ
  connect() {
    MicroModal.init()
    this.setOpenModalEvent()
  }

  // デストラクタ
  disconnect() {
    this.unsetOpenModalEvent()
  }

  setOpenModalEvent() {
    this.modals = document.getElementsByClassName("open-modal-event")
    Array.from(this.modals).forEach(target => {
      target.addEventListener('click', this.openModal)
    })
  }

  unsetOpenModalEvent() {
    Array.from(this.modals).forEach(target => {
      target.removeEventListener('click', this.openModal)
    })
  }

  openModal(event) {
    const elem = event.currentTarget
    const modal = elem.dataset.modalId
    console.log(modal)
    console.log(elem)
    MicroModal.show(modal)
  }
}
