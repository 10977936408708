import { Controller } from "@hotwired/stimulus"
import { Datepicker } from "vanillajs-datepicker"
import { getOptions } from "../../datepicker_option"


export default class extends Controller {
  static targets = []

  connect() {
    this.init()
  }

  disconnect() {
  }


  init() {
    // お届け先関連の出し分け
    this.selectShippingType()
    this.selectSampleShippingType()
    // 日付のところ設定
    this.setDatePicker()
  }


  setDatePicker() {
    const holidays = JSON.parse(document.getElementById('printOnlyHolidays').dataset.holidays)
    const options = getOptions(holidays, 'print_only')
    console.log(options)
    const shipping_hope_date = document.querySelector(".shipping_hope_date")
    new Datepicker(shipping_hope_date, options)
  }


  // お届け先情報選択時
  selectShippingType() {
    const shipping_form_area = document.getElementById('shipping_form_area')
    const shipping_type_other = document.querySelector(".shipping_type_other")
    const targets = [...shipping_form_area.querySelectorAll(".visible-address")]

    if(shipping_type_other.checked) {
      targets.forEach(i => this.toVisible(i))
    } else {
      targets.forEach(i => this.toHidden(i))
    }
  }


  // サンプルお届け先情報選択時
  selectSampleShippingType() {
    const sample_form_area = document.getElementById('sample_form_area')
    const is_sample_other_shipping_true = document.querySelector(".is_sample_other_shipping_true")
    const targets = [...sample_form_area.querySelectorAll(".visible-address")]

    if(is_sample_other_shipping_true.checked) {
      targets.forEach(i => this.toVisible(i))
    } else {
      targets.forEach(i => this.toHidden(i))
    }
  }

  toHidden(elem) {
    elem.style.display = "none"
  }

  toVisible(elem) {
    elem.style.display = ""
  }
}
