import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    customerStores: Array,
  };

  // コンストラクタ
  connect() {
    const elements = document.querySelectorAll(".js-print");
    elements.forEach((elem) => {
      elem.addEventListener('click', this.showPrint)
    })
  }

  // デストラクタ
  disconnect() {
    const elements = document.querySelectorAll(".js-print");
    elements.forEach((elem) => {
      elem.removeEventListener('click', this.showPrint)
    })
  }

  showPrint() {
    const print = document.getElementsByClassName('layout-print')[0]
    const screen = document.getElementsByClassName('layout-screen')[0]
    const printStyle = print.style.display;
    const screenStyle = screen.style.display;
    print.style.display = 'block';
    screen.style.display = 'none';
    window.print();
    print.style.display = printStyle;
    screen.style.display = screenStyle;
  }
}
