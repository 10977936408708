import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="manage-orders-show"
export default class extends Controller {
  // コンストラクタ
  connect() {
    console.debug("manage-orders-show");
    this.setEvent()
  }

  // デストラクタ
  disconnect() {}

  setEvent() {
    const orderStatusElm = document.getElementById('order_order_status');
    const orderStatusValue = orderStatusElm.options[orderStatusElm.selectedIndex].value;

    document.getElementById('update_button').addEventListener('click', function(event) {
      const orderStatusElm = document.getElementById('order_order_status');
      const clickedOrderStatusValue = orderStatusElm.options[orderStatusElm.selectedIndex].value;
      if (orderStatusValue != clickedOrderStatusValue) {
        if (!confirm('ステータスが変更されています。更新しますか？')) {
          event.preventDefault();
        }
      }
    });
  }
}
