import MicroModal from 'micromodal'
import { Controller } from "@hotwired/stimulus"

// ちょっと登録部分がイケてないけど、いったんこれでやっちゃう
// Connects to data-controller="shop-items-show"
export default class extends Controller {
  // コンストラクタ
  connect() {
    MicroModal.init()
    this.setSelectOptionEvent()
    this.setOpenModalEvent()
    this.setSelectImageEvent()
  }

  // デストラクタ
  disconnect() {
    this.unsetOpenModalEvent()
    this.unsetOpenModalEvent()
    this.unsetSelectImageEvent()
  }

  setOpenModalEvent() {
    this.modals = document.getElementsByClassName("open-modal-event")
    Array.from(this.modals).forEach(target => {
      target.addEventListener('click', this.openModal)
    })
  }

  setSelectOptionEvent() {
    this.selectOptions = document.getElementsByClassName("select-option-item-event")
    Array.from(this.selectOptions).forEach(target => {
      target.addEventListener('click', this.selectOption)
    })
  }

  unsetOpenModalEvent() {
    Array.from(this.modals).forEach(target => {
      target.removeEventListener('click', this.openModal)
    })
  }

  unsetSelectOptionEvent() {
    Array.from(this.selectOptions).forEach(target => {
      target.removeEventListener('click', this.selectOption)
    })
  }

  openModal(event) {
    const elem = event.currentTarget
    const modal = elem.dataset.modalId
    MicroModal.show(modal)
  }

  selectOption(event) {
    const elem = event.currentTarget
    const target = document.getElementById(elem.dataset.targetId)
    const targetLabel = document.getElementById(elem.dataset.targetLabel)
    const optionId = elem.dataset.optionId
    const optionName = elem.dataset.optionName
    const modal = elem.dataset.modalId

    target.value = optionId
    targetLabel.innerHTML = optionName

    MicroModal.close(modal)
  }

  setSelectImageEvent() {
    const triggerItems = document.querySelectorAll(".js-thumb-select-button");
    Array.from(triggerItems).forEach(target => {
      target.addEventListener('click', this.selectImage)
    })
  }

  unsetSelectImageEvent() {
    const triggerItems = document.querySelectorAll(".js-thumb-select-button");
    Array.from(triggerItems).forEach(target => {
      target.removeEventListener('click', this.selectImage)
    })
  }

  selectImage(event) {
    const triggerItems = document.querySelectorAll(".js-thumb-select-button");
    const targetImage = document.querySelector(".js-thumb-target img");
    Array.from(triggerItems).forEach(function (element) {
      element.classList.remove("active");
    });
    event.target.classList.add("active");
    targetImage.src = event.target.querySelector("img").getAttribute("src");
  }
}
