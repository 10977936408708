function setEvent() {
  const moreOptionGroupTrigger = document.querySelector(
    ".js-more-option-group-button"
  );

  moreOptionGroupTrigger.removeEventListener("click", showOptionGroupField);
  moreOptionGroupTrigger.addEventListener("click", showOptionGroupField);
}

function showOptionGroupField() {
  const target = this.closest("td").querySelector(
    ".js-more-option-group-field.invisible"
  );
  target.classList.remove("invisible");
  const nextTarget = this.closest("td").querySelector(
    ".js-more-option-group-field.invisible"
  );
  if (!nextTarget) {
    this.style.display = "none";
  }
}

export { setEvent }
