import { Controller } from "@hotwired/stimulus";

function sleep(waitSec, callbackFunc) {
  var waitTime = waitSec * 1000;

  // 経過時間（秒）
  var spanedSec = 0;

  // 0.1秒間隔で無名関数を実行
  var id = setInterval(function () {
    spanedSec += 100;

    // 経過時間 >= 待機時間の場合、待機終了。
    if (spanedSec >= waitTime) {
      // タイマー停止
      clearInterval(id);

      // 完了時、コールバック関数を実行
      if (callbackFunc) callbackFunc();
    }
  }, 100);
}

function changeCompany(event) {
  const dataset = event.target.dataset;
  let frame = document.querySelector("turbo-frame#areas");
  frame.src = `${dataset.selectUrl}?customer_id=${event.target.value}`;
  frame.reload();
  changeArea(event);
  sleep(0.3, function () {
    changeRole(document.querySelector("#customer_user_role"));
  });
}

function changeArea(event) {
  let areas = document.querySelector("turbo-frame#areas");
  const dataset = areas.dataset;
  const targets = areas.querySelectorAll(".js-select-area");
  const area_ids = [];
  targets.forEach((target) => {
    if (target.checked) {
      area_ids.push(target.value);
    }
  });

  let url = `${dataset.selectUrl}?`;
  area_ids.forEach((id) => {
    url += "area_ids[]=" + id + "&";
  });
  let selectedCustomer = document.querySelector("#customer_user_customer_id");
  url += "customer_id=" + selectedCustomer.value + "&";

  let frame = document.querySelector("turbo-frame#stores");
  frame.src = url;
  frame.reload();
  sleep(0.3, function () {
    changeRole(document.querySelector("#customer_user_role"));
  });
}

function changeRole(target) {
  const role = target.value;
  if (role == "honbu") {
    onOffTrubo("turbo-frame#areas", true);
    onOffTrubo("turbo-frame#stores", true);
  } else if (role == "area_manager") {
    onOffTrubo("turbo-frame#areas", false);
    onOffTrubo("turbo-frame#stores", true);
  } else {
    onOffTrubo("turbo-frame#areas", false);
    onOffTrubo("turbo-frame#stores", false);
  }
}

function onOffTrubo(id, disabled) {
  const storeLabels = document.querySelectorAll(`${id} label`);
  storeLabels.forEach((target) => {
    disabled
      ? target.classList.add("disabled")
      : target.classList.remove("disabled");
  });
  const stores = document.querySelectorAll(`${id} input[type=checkbox]`);
  stores.forEach((target) => {
    target.disabled = disabled;
  });
}

// Connects to data-controller="manage-customers-users-edit"
export default class extends Controller {
  // コンストラクタ
  connect() {
    console.debug("manage-customers-users-edit");
    this.setEvent()
  }

  // デストラクタ
  disconnect() {}

  setEvent() {
    document.addEventListener("change", function (e) {
      if (e.target.classList.contains("js-select-area")) {
        changeArea(e);
      }
      if (e.target.classList.contains("js-select-company")) {
        changeCompany(e);
      }
      if (e.target.classList.contains("js-select-role")) {
        changeRole(document.querySelector("#customer_user_role"));
      }
    });
    const ev = new Event("change", {
      bubbles: true,
      cancelable: true,
    });
    document.querySelector("#customer_user_role").dispatchEvent(ev);
  }
}
