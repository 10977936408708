import { Controller } from "@hotwired/stimulus";
import { setEvent as selectSetEvent } from '../../../manage/shared/select';
import { setEvent as imageSetEvent } from './image';
import { setEvent as moreButtonSetEvent } from './more_option_group_button'

// Connects to data-controller="manage-items-form"
export default class extends Controller {
  // コンストラクタ
  connect() {
    console.debug("manage-items-form");
    this.setEvent()
  }

  // デストラクタ
  disconnect() {}

  setEvent() {
    selectSetEvent()
    imageSetEvent()
    moreButtonSetEvent()
  }
}
