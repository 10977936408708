import MicroModal from 'micromodal'
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="manage-credit-judgements-index"
export default class extends Controller {
  // コンストラクタ
  connect() {
    console.debug("manage-credit-judgements-index")
    MicroModal.init()
    this.setOpenModalEvent()
    this.setCloseModalEvent()
  }

  // デストラクタ
  disconnect() {
    this.unsetOpenModalEvent()
    this.unsetCloseModalEvent()
  }

  setOpenModalEvent() {
    this.modals = document.getElementsByClassName("js-open-modal")
    Array.from(this.modals).forEach(target => {
      target.addEventListener('click', this.openModal)
    })
  }

  unsetOpenModalEvent() {
    this.modals = document.getElementsByClassName("js-open-modal")
    Array.from(this.modals).forEach(target => {
      target.removeEventListener('click', this.openModal)
    })
  }

  setCloseModalEvent() {
    this.modals = document.getElementsByClassName("js-close-modal")
    Array.from(this.modals).forEach(target => {
      target.addEventListener('click', this.closeModal)
    })
  }

  unsetCloseModalEvent() {
    this.modals = document.getElementsByClassName("js-close-modal")
    Array.from(this.modals).forEach(target => {
      target.removeEventListener('click', this.closeModal)
    })
  }

  openModal(event) {
    const elem = event.currentTarget
    const modal = elem.dataset.modalId
    MicroModal.show(modal)
  }

  closeModal(event) {
    const elem = event.currentTarget
    const modal = elem.dataset.modalId
    MicroModal.close(modal)
  }
}
