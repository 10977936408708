import { Controller } from "@hotwired/stimulus";

import { setEvent as selectSetEvent } from "../../../manage/shared/select";
import { initImageUploadEvent } from "../../../manage/shared/image_upload";

// Connects to data-controller="manage-customers-edit"
export default class extends Controller {
  // コンストラクタ
  connect() {
    console.debug("manage-customers-edit");
    this.setEvent()
  }

  // デストラクタ
  disconnect() {}

  setEvent() {
    selectSetEvent()
    initImageUploadEvent()
  }
}
