import Choices from "choices.js";

const setEvent = function setEvent() {
  const options = {
    removeItemButton: true,
    allowHTML: false
  };
  const targets = document.querySelectorAll(".js-choice");

  targets.forEach((target) =>
    new Choices(target, options)
  )
}

export { setEvent }
