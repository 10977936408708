import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="shop--carts--payment"
export default class extends Controller {

  static targets = [
    "stores",
    "isDispatchShipping",
    "isOtherShipping",
    "shippingSetting",
    "standardItem",
    "standardShippingPrice",
    "standardTotalPrice",
    "largeItem",
    "largeShippingPrice",
    "largeTotalPrice",
    "totalItem",
    "shippingTotalPrice",
    "cartTotalPrice",
    "otherPref",
    "customerStore",
  ]

  connect() {
    this.setVariables()
    this.toggleOtherAddress()
  }

  // 配送先情報の初期値設定(送料、消費税)
  setVariables() {
    // 発注した店舗情報の設定
    this.stores = JSON.parse(this.storesTarget.dataset.stores)

    // 送料などの設定
    this.shippingPrices = JSON.parse(this.shippingSettingTarget.dataset.prices)
    this.shippingTaxes = this.getTaxes(JSON.parse(this.shippingSettingTarget.dataset.taxes))

    // 通常商品の料金周り
    if(this.hasStandartItem()) {
      this.standardItemCount = this.toNumber(this.standardItemTarget.dataset.itemCount)
      this.standardItemPrice = this.toNumber(this.standardItemTarget.dataset.itemPrice)
    }

    // 大型商品の料金周り
    if(this.hasLargeItem()) {
      this.largeItemCount = this.toNumber(this.largeItemTarget.dataset.itemCount)
      this.largeItemPrice = this.toNumber(this.largeItemTarget.dataset.itemPrice)
    }

    // 商品全体の料金周り
    this.totalItemPrice = this.toNumber(this.totalItemTarget.dataset.price)

    // 総計
    this.shippingTotalPrice = this.toNumber(this.shippingTotalPriceTarget.innerText)
    this.cartTotalPrice = this.toNumber(this.cartTotalPriceTarget.innerText)
  }

  // その他の住所
  toggleOtherAddress() {
    const elem = document.querySelector(".is-other-address")
    const otherAddress = document.getElementById("other-address-form")
    const klass = 'invisible'
    if(elem.checked) {
      if(otherAddress.classList.contains(klass)) {
        otherAddress.classList.remove(klass)
      }
    } else {
      if(!otherAddress.classList.contains(klass)) {
        otherAddress.classList.add(klass)
      }
    }
  }

  // 商品料金再計算
  updateShipping() {
    // 登場人物列挙しておく
    const isOtherShipping = this.isOtherShippingTarget.checked

    // 都道府県が設定されていなかったら送料などの設定を消して戻る
    if (isOtherShipping && !this.otherPrefTarget.value) {
      this.setEmptyShipping()
      return
    }
    if (!isOtherShipping && !this.customerStoreTarget.value) {
      this.setEmptyShipping()
      return
    }

    // 都道府県を取得する
    const pref_id = isOtherShipping
                    ? Number(this.otherPrefTarget.value)
                    : this.findPrefIdByStoreId(Number(this.customerStoreTarget.value))

    this.setShippingText(pref_id)
  }



  setShippingText(pref_id) {
    // 送料計
    let totalShippingPriceWithTax = 0

    // 送料を取得する
    const shippings = this.findShippingPriceByPrefId(pref_id)

    // 通常便の設定
    if(this.hasStandartItem()) {
      // 消費税取得
      const rate = this.shippingTaxes.standard.tax_rate

      // 送料計算
      const isDispatch = this.isDispatchShippingTarget.checked  // おまとめ配送
      const normal = this.standardItemCount * shippings.standard.price
      const dispatch = shippings.standard.price
      const shippingPrice = isDispatch ? dispatch : normal
      const shippingPriceWithTax = this.with_tax(shippingPrice, rate)

      // 総計に加算
      totalShippingPriceWithTax += shippingPriceWithTax

      // 料金設定
      this.standardShippingPriceTarget.innerText = shippingPriceWithTax.toLocaleString()
      this.standardTotalPriceTarget.innerText = (shippingPriceWithTax + this.standardItemPrice).toLocaleString()
    }

    // 大型便の設定
    if(this.hasLargeItem()) {
      // 消費税取得
      const rate = this.shippingTaxes.large.tax_rate

      // 送料計算
      const shippingPrice = this.largeItemCount * shippings.large.price
      const shippingPriceWithTax = this.with_tax(shippingPrice, rate)

      // 総計に加算
      totalShippingPriceWithTax += shippingPriceWithTax

      // 料金設定
      this.largeShippingPriceTarget.innerText = shippingPriceWithTax.toLocaleString()
      this.largeTotalPriceTarget.innerText  = (this.largeItemPrice + shippingPriceWithTax).toLocaleString()
    }

    // 総計の設定
    this.shippingTotalPriceTarget.innerText = totalShippingPriceWithTax.toLocaleString()
    this.cartTotalPriceTarget.innerText = (this.totalItemPrice + totalShippingPriceWithTax).toLocaleString()

    // 送料合計をhidden_parameterに入れておく
    const shippintTotalField = document.querySelector(".hidden-shipping-total-price")
    shippintTotalField.value = totalShippingPriceWithTax
  }

  setEmptyShipping() {
    // 通常便の設定
    if(this.hasStandartItem()) {
      this.standardShippingPriceTarget.innerText = "-"
      this.standardTotalPriceTarget.innerText = "-"
    }

    // 大型便の設定
    if(this.hasLargeItem()) {
      this.largeShippingPriceTarget.innerText = "-"
      this.largeTotalPriceTarget.innerText  = "-"
    }

    // 総計の設定
    this.shippingTotalPriceTarget.innerText = "-"
    this.cartTotalPriceTarget.innerText = "-"
  }

  with_tax(price, rate) {
    return price + Math.floor(price / 100.0 * rate)
  }

  toNumber(value) {
    const item = value.trim()
    if (item === '-') {
      return 0
    }
    return Number(item.replace(/,/g, ""))
  }

  getTaxes(taxes) {
    const standard = taxes.find(tax => tax.delivery_type === 'standard')
    const large = taxes.find(tax => tax.delivery_type === 'large')
    return {standard, large}
  }

  findStoreByStoreId(store_id) {
    return this.stores.find(store => store.id === Number(store_id))
  }

  findPrefIdByStoreId(store_id) {
    const store = this.findStoreByStoreId(store_id)
    return store.pref_id
  }

  findShippingPriceByPrefId(pref_id) {
    const standard = this.shippingPrices.standard.find((i => i.pref_id === pref_id))
    const large = this.shippingPrices.large.find((i => i.pref_id === pref_id))
    return {standard, large}
  }

  hasStandartItem() {
    return !!document.getElementById('has-standard-item')
  }

  hasLargeItem() {
    return !!document.getElementById('has-large-item')
  }
}
