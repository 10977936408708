import Sortable from "sortablejs";
import { DirectUpload } from "@rails/activestorage";

// 公式参照 https://railsguides.jp/active_storage_overview.html#%E3%83%A9%E3%82%A4%E3%83%96%E3%83%A9%E3%83%AA%E3%82%84%E3%83%95%E3%83%AC%E3%83%BC%E3%83%A0%E3%83%AF%E3%83%BC%E3%82%AF%E3%81%A8%E3%81%AE%E7%B5%B1%E5%90%88
const uploadFile = (input, file) => {
  const url = input.dataset.directUploadUrl;
  const token = input.dataset.directUploadToken;
  const attachmentName = input.dataset.directUploadAttachmentName;
  const upload = new DirectUpload(file, url, token, attachmentName);

  upload.create((error, blob) => {
    if (error) {
      // エラーハンドリングをここに書く
    } else {
      const draggable = document.querySelector(".js-draggable-image");
      const template = draggable.querySelector(".template-item");
      const itemImage = template.querySelector(".item-image").cloneNode(true);

      const fileReader = new FileReader();
      fileReader.onload = function (e) {
        itemImage.querySelector("img").src = e.target.result;
      };
      fileReader.readAsDataURL(file);

      const id = `item_image_ids_${draggable.childElementCount}`;

      const label = itemImage.querySelector("label");
      label.htmlFor = id;
      label.onclick = deleteImage

      const hiddenField = itemImage.querySelector("input[type=hidden]");
      hiddenField.setAttribute("value", blob.signed_id);
      hiddenField.removeAttribute("disabled");
      hiddenField.id = id;

      draggable.appendChild(itemImage);
    }
  });
};

function deleteImage(event) {
    const target = this.closest(".item-image");
    target.remove();
  }

function setEvent() {
  const input = document.querySelector("input[type=file].js-select-image");
  // 通常のファイル選択へのバインド
  input.addEventListener("change", (event) => {
    Array.from(input.files).forEach((file) => uploadFile(input, file));
    // 選択されたファイルをここで入力からクリアしてもよい
    input.value = null;
  });

  const deleteImageTriggers = document.querySelectorAll(".js-delete-image");

  deleteImageTriggers.forEach((trigger) =>
    trigger.addEventListener("click", deleteImage)
  );

  const element = document.querySelector(".js-draggable-image");
  const sortable = Sortable.create(element);
}

export { setEvent }
