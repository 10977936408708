import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";

// 公式参照 https://railsguides.jp/active_storage_overview.html#%E3%83%A9%E3%82%A4%E3%83%96%E3%83%A9%E3%83%AA%E3%82%84%E3%83%95%E3%83%AC%E3%83%BC%E3%83%A0%E3%83%AF%E3%83%BC%E3%82%AF%E3%81%A8%E3%81%AE%E7%B5%B1%E5%90%88
function uploadImage(event) {
  console.log(this)
  const input = this
  const url = this.dataset.directUploadUrl;
  const token = this.dataset.directUploadToken;
  const attachmentName = this.dataset.directUploadAttachmentName;
  const upload = new DirectUpload(this.files[0], url, token, attachmentName);

  upload.create((error, blob) => {
    if (error) {
      // エラーハンドリングをここに書く
    } else {
      const itemImage = input.closest(".js-image-upload");

      const fileReader = new FileReader();
      fileReader.onload = function (e) {
        itemImage.querySelector("img").src = e.target.result;
      };
      fileReader.readAsDataURL(input.files[0]);

      const hiddenField = itemImage.querySelector("input[type=hidden]");
      hiddenField.setAttribute("value", blob.signed_id);

      const deleteIcon =
      input.closest(".js-image-upload").querySelector(".js-delete-image");
      deleteIcon.style.visibility = "visible";

      input.value = null;
    }
  });
};

function deleteImage(event) {
  const target = this.closest(".item-image").querySelector("img");
  target.src = "/images/no_image.jpg";
  this.style.visibility = "hidden";
  const hiddenField = this.querySelector("input[type=hidden]");
  hiddenField.setAttribute("value", "");
}

// Connects to data-controller="manage-share-image-upload"
export default class extends Controller {
  // コンストラクタ
  connect() {
    console.debug("manage-share-image-upload");
    this.setEvent()
  }

  // デストラクタ
  disconnect() {}

  setEvent() {
    const selectImageTriggers = document.querySelectorAll(".js-select-image");

    selectImageTriggers.forEach((trigger) =>
      trigger.addEventListener("change", uploadImage)
    );
    const deleteImageTriggers = document.querySelectorAll(".js-delete-image");

    deleteImageTriggers.forEach((trigger) =>
      trigger.addEventListener("click", deleteImage)
    );
  }
}
