import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "rep",
    "selectArea",
    "selectStore",
  ]

  connect() {
    this.changeRole()
  }

  disconnect() {

  }

  changeRole() {
    const role = document.querySelector(".customer_user_role").value

    console.log(role.value)
    if(role === 'honbu') {
      this.toHidden(this.repTarget)
    } else if(role === 'area_manager') {
      this.toVisible(this.repTarget)
      this.toHidden(this.selectStoreTarget)
      this.toVisible(this.selectAreaTarget)
    } else if(role === 'store_manager') {
      this.toVisible(this.repTarget)
      this.toVisible(this.selectStoreTarget)
      this.toHidden(this.selectAreaTarget)
    }
  }

  toHidden(elem) {
    elem.style.display = "none"
  }

  toVisible(elem) {
    elem.style.display = ""
  }
}