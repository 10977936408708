export const getOptions = (holidays = [], flyerOrderType= "print_only") => {
  const datesDisabled = holidays.map((holiday) => new Date(holiday))

  console.log(datesDisabled)
  const options = {
    format: 'yyyy/mm/dd',
    minDate: getDeadline(flyerOrderType),
    datesDisabled: datesDisabled,
  }

  return {...options}
}

const getDeadline = (flyerOrderType) => {
  // 期限のオプションを取得
  const deadlineOptions = {
    print_only: [11, 10, 16, 15, 14, 13, 12],
    insert_only: [9, 8, 14, 13, 12, 11, 10],
    print_and_insert: [16, 15, 21, 20, 19, 18, 17],
    posting: [13, 12, 18, 17, 16, 15, 14],
  }
  const targetDeadline = deadlineOptions[flyerOrderType]

  // 今日を取得
  const today = new Date()
  const minDate = today
  minDate.setDate(today.getDate() + targetDeadline[today.getDay()])

  return minDate
}
