import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

// Connects to data-controller="manage-flyer-invoice-index"
export default class extends Controller {
  static values = {
    customerStores: Array,
  };

  // コンストラクタ
  connect() {
    console.debug("manage-flyer-invoice-index");
    this.setEvent()

    const elements = document.querySelectorAll(".js-print");
    elements.forEach((elem) => {
      elem.addEventListener('click', this.showPrint)
    })
  }

  // デストラクタ
  disconnect() {
    const elements = document.querySelectorAll(".js-print");
    elements.forEach((elem) => {
      elem.removeEventListener('click', this.showPrint)
    })
  }

  showPrint() {
    const print = document.getElementsByClassName('layout-print')[0]
    const screen = document.getElementsByClassName('layout-screen')[0]
    const printStyle = print.style.display;
    const screenStyle = screen.style.display;
    print.style.display = 'block';
    screen.style.display = 'none';
    window.print();
    print.style.display = printStyle;
    screen.style.display = screenStyle;
  }

  setEvent() {
    const customerChoiceOptions = {
      removeItemButton: true,
      allowHTML: false,
    };
    const customerStoreChoiceOptions = {
      shouldSort: false,
      removeItemButton: true,
      allowHTML: false,
    };
    const customerChoice = new Choices(
      document.querySelector(".js-choice-customer"),
      customerChoiceOptions
    );
    const customerStoreChoice = new Choices(
      document.querySelector(".js-choice-customer-stores"),
      customerStoreChoiceOptions
    );

    customerChoice.passedElement.element.addEventListener(
      "change",
      () => {
        this.changeCustomerStoreSelect(
          customerChoice,
          customerStoreChoice,
          this.customerStoresValue
        );
      },
      true
    );
  }

  changeCustomerStoreSelect(
    customerChoice,
    customerStoreChoice,
    customerStores
  ) {
    const customerIds = customerChoice.getValue(true);
    const stores = customerStores.filter((store) =>
      customerIds === String(store.customer_id)
    );

    const selectedIds = customerStoreChoice.getValue(true);
    customerStoreChoice.clearStore();
    customerStoreChoice.setChoices(
      [{id: '', name: '指定なし'}].concat(stores).map((s) => ({ value: String(s.id), label: s.name })),
      "value",
      "label",
      true
    );
    customerStoreChoice.setChoiceByValue(selectedIds);
  }
}
