import { Controller } from "@hotwired/stimulus"
import { Datepicker } from "vanillajs-datepicker"
import { getOptions } from "../datepicker_option"



// 表示制御
// hidden-init を class につけると、初期表示時に表示しない
// hidden-posting を class につけると flyer_type が posting を選択したときに、表示されないようにする
// hidden-insert を class につけると flyer_order_type が insert_only と print_and_insert を選択したときに、表示されないようにする
// hidden-order-type-insert-only を class につけると flyer_order_type が insert_only を選択したときに、表示されないようにする
// hidden-print を class につけると flyer_order_type が print_only を選択したときに、表示されないようにする


// Connects to data-controller="shop--flyer--orders--form"
export default class extends Controller {
  static targets = [

  ]

  connect() {
    this.setDatePicker()
    this.setInitialVisblities()
  }

  disconnect() {
  }

  setInitialVisblities() {
    this.form_front_order_print_type = document.getElementById("form_front_order_print_type")
    this.front_flyer_design_area = document.getElementById("form_front_flyer_customer_design")
    this.front_flyer_no_area = document.getElementById("form_front_flyer_no_or_order_date")
    this.form_back_order_print_type = document.getElementById("form_back_order_print_type")
    this.back_flyer_design_area = document.getElementById("form_back_flyer_customer_design")
    this.back_flyer_no_area = document.getElementById("form_back_flyer_no_or_order_date")
    this.form_sample_address_group = document.getElementById("form_sample_address_group")
    this.form_other_address_group = document.getElementById("form_other_address_group")
    this.form_shipping_hope_date = document.getElementById("form_shipping_hope_date")
    this.printed_form_area = document.getElementById("printed_form_area")
    this.insert_form_area = document.getElementById("insert_form_area")

    this.setVisibleOrderType()
    this.setVisibleFrontOrderPrintType()
    this.setVisibleBackOrderPrintType()
    this.calcVisiblity()
    this.selectShippingType()
    this.selectSampleShippingType()
    this.toggleIsSample()
  }

  setDatePicker() {
    const insertHolidays = JSON.parse(document.getElementById('insertOnlyHolidays').dataset.holidays)
    const printHolidays = JSON.parse(document.getElementById('printOnlyHolidays').dataset.holidays)

    this.datepickerInsertOptions = getOptions(insertHolidays, 'insert_only')
    this.datepickerPrintAndInsertOptions = getOptions(insertHolidays, 'print_and_insert')
    this.datepickerPrintOptions = getOptions(printHolidays, 'print_only')
    this.datepickerPostingOptions = getOptions(printHolidays, 'posting')
    const insert_hope_date = document.querySelector(".insert_hope_date")
    this.insertHopeDatePicker = new Datepicker(insert_hope_date, {...this.datepickerInsertOptions})
    const shipping_hope_date = document.querySelector(".shipping_hope_date")
    this.shippingHopeDatePicker = new Datepicker(shipping_hope_date, {...this.datepickerPrintOptions})
  }

  // チラシの種類の選択したときの表示非表示
  setVisibleOrderType() {
    const order_type_insert = document.querySelector(".flyer_type_insert")
    const order_type_posting = document.querySelector(".flyer_type_posting")

    const print_only_label = document.querySelector(".flyer_order_type_print_only_label")
    const insert_only_label = document.querySelector(".flyer_order_type_insert_only_label")
    const print_and_insert_label = document.querySelector(".flyer_order_type_print_and_insert_label")
    const print_only = document.querySelector(".flyer_order_type_print_only")

    if(order_type_insert.checked) {
      print_only_label.style.display = ""
      insert_only_label.style.display = ""
      print_and_insert_label.style.display = ""
    }

    if(order_type_posting.checked) {
      this.toHidden(insert_only_label)
      this.toHidden(print_and_insert_label)
      print_only.checked = true
    }
  }

  setVisibleFrontOrderPrintType() {
    const order_print_type_new = document.querySelector(".front_order_print_type_new")
    const order_print_type_update = document.querySelector(".front_order_print_type_update")

    const flyer_design_area = document.getElementById("form_front_flyer_customer_design")
    const flyer_no_area = document.getElementById("form_front_flyer_no_or_order_date")

    // 初期
    if(!order_print_type_new.checked && !order_print_type_update.checked) {
      this.toHidden(flyer_design_area)
      this.toHidden(flyer_no_area)
      return
    }

    if(order_print_type_new.checked) {
      this.toVisible(flyer_design_area)
      this.toHidden(flyer_no_area)
    }

    if(order_print_type_update.checked) {
      this.toHidden(flyer_design_area)
      this.toVisible(flyer_no_area)
    }
  }

  setVisibleBackOrderPrintType() {
    const order_print_type_new = document.querySelector(".back_order_print_type_new")
    const order_print_type_update = document.querySelector(".back_order_print_type_update")

    const flyer_design_area = document.getElementById("form_back_flyer_customer_design")
    const flyer_no_area = document.getElementById("form_back_flyer_no_or_order_date")

    // 初期
    if(!order_print_type_new.checked && !order_print_type_update.checked) {
      this.toHidden(flyer_design_area)
      this.toHidden(flyer_no_area)
    }

    if(order_print_type_new.checked) {
      this.toVisible(flyer_design_area)
      this.toHidden(flyer_no_area)
    }

    if(order_print_type_update.checked) {
      this.toHidden(flyer_design_area)
      this.toVisible(flyer_no_area)
    }
  }



  calcVisiblity() {
    // 表示非表示の設定をする対象
    const postingHiddenTargets = [...document.querySelectorAll(".hidden-posting")]
    const insertHiddenTargets = [...document.querySelectorAll(".hidden-insert")]
    const insertOnlyHiddenTargets = [...document.querySelectorAll(".hidden-only-insert")]
    const printHiddenTargets = [...document.querySelectorAll(".hidden-print")]
    const orderTypeInsertOnlyHiddenTargets = [...document.querySelectorAll(".hidden-order-type-insert-only")]

    // inputの有効・無効を設定をする対象
    const postingDisabledTargets = [...document.querySelectorAll(".disabled-posting")]
    const insertDisabledTargets = [...document.querySelectorAll(".disabled-insert")]

    // チラシタイプ
    const flyer_type_insert = document.querySelector(".flyer_type_insert")
    const flyer_type_posting = document.querySelector(".flyer_type_posting")

    // 注文タイプ
    const flyer_order_type_print_only = document.querySelector(".flyer_order_type_print_only")
    const flyer_order_type_insert_only = document.querySelector(".flyer_order_type_insert_only")
    const flyer_order_type_print_and_insert = document.querySelector(".flyer_order_type_print_and_insert")

    // デザイン＆テンプレートの表示制御(折込のみの場合に制御する)
    const front_order_print_type_new = document.querySelector(".front_order_print_type_new")
    const front_order_print_type_update = document.querySelector(".front_order_print_type_update")
    const back_order_print_type_new = document.querySelector(".back_order_print_type_new")
    const back_order_print_type_update = document.querySelector(".back_order_print_type_update")
    const sample_form_area = document.querySelector("#sample_form_area")

    // お届け日
    const shippingTypeInsertTargets = [...document.querySelectorAll(".hidden-shipping-type-store"), ...document.querySelectorAll(".hidden-shipping-type-other")]
    const shipping_type_store = document.querySelector(".shipping_type_store")
    const shipping_type_other = document.querySelector(".shipping_type_other")


    // 注文内容を選択

    // 未選択の場合
    if (!flyer_type_posting.checked && !flyer_type_insert.checked) {
      insertHiddenTargets.forEach(i => this.toHidden(i))
      insertOnlyHiddenTargets.forEach(i => this.toHidden(i))
      postingHiddenTargets.forEach(i => this.toHidden(i))
      printHiddenTargets.forEach(i => this.toHidden(i))
      orderTypeInsertOnlyHiddenTargets.forEach(i => this.toHidden(i))

      insertDisabledTargets.forEach(i => this.toDisabled(i))
      postingDisabledTargets.forEach(i => this.toDisabled(i))
      return
    }

    // ポスティングチラシを選択
    if (flyer_type_posting.checked) {
      insertHiddenTargets.forEach(i => this.toVisible(i))
      insertOnlyHiddenTargets.forEach(i => this.toVisible(i))
      orderTypeInsertOnlyHiddenTargets.forEach(i => this.toVisible(i))
      postingHiddenTargets.forEach(i => this.toHidden(i))
      printHiddenTargets.forEach(i => this.toHidden(i))

      insertDisabledTargets.forEach(i => this.toEnabled(i))
      postingDisabledTargets.forEach(i => this.toDisabled(i))

      this.toVisible(front_order_print_type_new.parentElement)
      this.toVisible(back_order_print_type_new.parentElement)
      this.shippingHopeDatePicker.setOptions({ ...this.datepickerPostingOptions }, { forceRefresh: true })

      flyer_order_type_print_only.checked = true
      return
    }

    // 折込チラシを選択
    if(flyer_type_insert.checked) {
      postingHiddenTargets.forEach(i => this.toVisible(i))
      insertOnlyHiddenTargets.forEach(i => this.toHidden(i))

      insertDisabledTargets.forEach(i => this.toDisabled(i))
      postingDisabledTargets.forEach(i => this.toEnabled(i))

      this.toVisible(front_order_print_type_new.parentElement)
      this.toVisible(back_order_print_type_new.parentElement)
      this.toVisible(sample_form_area)

      this.shippingHopeDatePicker.setOptions({ ...this.datepickerPrintOptions }, { forceRefresh: true })

      // 印刷のみを選択
      if(flyer_order_type_print_only.checked) {
        insertHiddenTargets.forEach(i => this.toVisible(i))
        printHiddenTargets.forEach(i => this.toHidden(i))
        orderTypeInsertOnlyHiddenTargets.forEach(i => this.toVisible(i))

        if (shipping_type_store.checked || shipping_type_other.checked) {
          shippingTypeInsertTargets.forEach(i => this.toVisible(i))
        }
      }

      // 折込のみを選択
      if(flyer_order_type_insert_only.checked) {
        insertHiddenTargets.forEach(i => this.toHidden(i))
        printHiddenTargets.forEach(i => this.toVisible(i))
        orderTypeInsertOnlyHiddenTargets.forEach(i => this.toHidden(i))
        shippingTypeInsertTargets.forEach(i => this.toHidden(i))

        front_order_print_type_update.click()
        back_order_print_type_update.click()
        this.toHidden(front_order_print_type_new.parentElement)
        this.toHidden(back_order_print_type_new.parentElement)
        this.toHidden(sample_form_area)

        if(typeof this.insertHopeDatePicker !== 'undefined') {
          console.log("insert option:", this.datepickerInsertOptions)
          this.insertHopeDatePicker.setOptions({...this.datepickerInsertOptions}, { forceRefresh: true })
          console.log(this.insertHopeDatePicker)
        }
      }

      // 印刷+折込を選択
      if(flyer_order_type_print_and_insert.checked) {
        insertHiddenTargets.forEach(i => this.toHidden(i))
        printHiddenTargets.forEach(i => this.toVisible(i))
        orderTypeInsertOnlyHiddenTargets.forEach(i => this.toVisible(i))
        shippingTypeInsertTargets.forEach(i => this.toHidden(i))

        if (typeof this.insertHopeDatePicker !== 'undefined') {
          this.insertHopeDatePicker.setOptions({ ...this.datepickerPrintAndInsertOptions }, { forceRefresh: true, })
          this.insertHopeDatePicker.setDate({clear: true})
        }
      }
    }
  }

  // お届け先情報選択時
  selectShippingType() {
    const shipping_type_store = document.querySelector(".shipping_type_store")
    const shipping_type_other = document.querySelector(".shipping_type_other")
    const targets = [...this.form_other_address_group.querySelectorAll(".visible-address")]
    const shippingTypeInsertTargets = [...document.querySelectorAll(".hidden-shipping-type-store"), ...document.querySelectorAll(".hidden-shipping-type-other")]

    if (!shipping_type_store.checked && !shipping_type_other.checked) {
      shippingTypeInsertTargets.forEach(i => this.toHidden(i))
      targets.forEach(i => this.toHidden(i))
      return
    } else {
      shippingTypeInsertTargets.forEach(i => this.toVisible(i))
    }


    if(shipping_type_other.checked) {
      targets.forEach(i => this.toVisible(i))
    } else {
      targets.forEach(i => this.toHidden(i))
    }
  }


  // サンプルお届け先情報選択時
  selectSampleShippingType() {
    const is_sample_other_shipping_true = document.querySelector(".is_sample_other_shipping_true")
    const targets = [...this.form_sample_address_group.querySelectorAll(".visible-address")]

    if(is_sample_other_shipping_true.checked) {
      targets.forEach(i => this.toVisible(i))
    } else {
      targets.forEach(i => this.toHidden(i))
    }
  }

  // サンプル
  toggleIsSample() {
    const is_sample = document.querySelector(".is_sample")

    if(is_sample.checked) {
      this.toVisible(this.form_sample_address_group)
    } else {
      this.toHidden(this.form_sample_address_group)
    }
  }


  toHidden(elem) {
    elem.style.display = "none"
  }

  toVisible(elem) {
    elem.style.display = ""
  }

  toDisabled(elem) {
    elem.style.display = "none"
    elem.disabled = true
  }

  toEnabled(elem) {
    elem.style.display = ""
    elem.disabled = false
  }
}
