document.addEventListener("turbo:load", (event) => {
  const menuToggleHandler = document.querySelector(".js-menu-toggle");
  const wholeDocument = document.body;
  const target = document.querySelector(".js-menu-target");
  const headerNavItems = document.getElementsByClassName("js-header-nav-item");

  let isOpen = false;
  console.log(menuToggleHandler);

  menuToggleHandler.addEventListener("click", (e) => {
    if (!isOpen) {
      target.classList.add("menu-open");
      menuToggleHandler.classList.add("toggle-on");
      isOpen = true;
    } else {
      target.classList.remove("menu-open");
      menuToggleHandler.classList.remove("toggle-on");
      isOpen = false;
    }
  });
});
