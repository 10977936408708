import Sortable from "sortablejs";

function saveSort(e) {
  const elements = e.target.querySelectorAll(".sortable-item");
  elements.forEach((element, index) => {
    element.querySelector('input[name*="[order]"]').value = index;
  });
}

function setEvent() {
  const elements = document.querySelectorAll(".js-sortable-item");

  elements.forEach((element) => {
    const sortable = Sortable.create(element, { onEnd: saveSort });
    element.addEventListener("cocoon:before-remove", function (e) {
      console.log("cocoon:before-remove");
    });
  });
}

export { setEvent };
