import { Controller } from "@hotwired/stimulus";

const deleteRow = (event) => {
  const row = event.target.closest("tr");
  row.querySelector(".quantity").value = "";
  row.querySelector(".unit_price").value = "";
  row.style.display = "none";
}

const addRow = (event) => {
  const index = Number(event.target.dataset.index);
  const row = event.target.closest("tr");

  // レコード生成
  let clone_element = row.cloneNode(true);
  clone_element.querySelector(".js-delete-row").style.display = "";
  clone_element.querySelector(".js-add-row").style.display = "none";
  row.before(clone_element);

  // 初期化
  row.querySelectorAll("input").forEach((input) => {
    const v = input.value;
    if(v == "posting" || v == "insert"){
      // 何もしない
    } else {
      input.value = "";
    }
    input.name = input.name.replace(index, index + 1);
  });
  event.target.dataset.index = index + 1;
}

// Connects to data-controller="manage-flyer-prices-edit"
export default class extends Controller {
  // コンストラクタ
  connect() {
    console.debug("manage-flyer-prices-edit");
    document.addEventListener("click", this.setEvent)
    // 暫定対応、配列のindexの重複を防ぐため
    document.querySelectorAll(".js-add-row").forEach(function(event, i) {
      const index = Number(event.dataset.index);
      const row = event.closest("tr");
      row.querySelectorAll("input").forEach((input) => {
        input.name = input.name.replace(index, index + 1000 * (i + 1));
      });
      event.dataset.index = index + 1000 * (i + 1);
    });
  }

  // デストラクタ
  disconnect() {
    document.removeEventListener("click", this.setEvent)
  }

  setEvent(e) {
    if (e.target.classList.contains("js-delete-row")) {
      deleteRow(e);
    }
    if (e.target.classList.contains("js-add-row")) {
      addRow(e);
    }
  }
}
