import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

// Connects to data-controller="manage-share-select"
export default class extends Controller {
  // コンストラクタ
  connect() {
    console.debug("manage-share-select");
    this.setEvent()
  }

  // デストラクタ
  disconnect() {}

  setEvent() {
    const options = {
        removeItemButton: true,
        allowHTML: false
      };
      const targets = document.querySelectorAll(".js-choice");

      targets.forEach((target) =>
        new Choices(target, options)
      )
  }
}
