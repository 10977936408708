import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

// Connects to data-controller="manage-flyer-orders-index"
export default class extends Controller {
  static values = {
    customerStores: Array,
  };

  // コンストラクタ
  connect() {
    console.debug("manage-flyer-orders-index");
    this.setEvent()
  }

  // デストラクタ
  disconnect() {}

  setEvent() {
    const customerChoiceOptions = {
      removeItemButton: true,
      allowHTML: false,
    };
    const customerStoreChoiceOptions = {
      removeItemButton: true,
      allowHTML: false,
    };
    const customerChoice = new Choices(
      document.querySelector(".js-choice-customer"),
      customerChoiceOptions
    );
    const customerStoreChoice = new Choices(
      document.querySelector(".js-choice-customer-stores"),
      customerStoreChoiceOptions
    );

    customerChoice.passedElement.element.addEventListener(
      "change",
      () => {
        this.changeCustomerStoreSelect(
          customerChoice,
          customerStoreChoice,
          this.customerStoresValue
        );
      },
      true
    );
  }

  changeCustomerStoreSelect(
    customerChoice,
    customerStoreChoice,
    customerStores
  ) {
    const customerIds = customerChoice.getValue(true);
    const stores = customerStores.filter((store) =>
      customerIds.includes(String(store.customer_id))
    );

    const selectedIds = customerStoreChoice.getValue(true);
    customerStoreChoice.clearStore();
    customerStoreChoice.setChoices(
      stores.map((s) => ({ value: String(s.id), label: s.name })),
      "value",
      "label",
      true
    );
    customerStoreChoice.setChoiceByValue(selectedIds);
  }
}
