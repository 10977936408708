import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  // コンストラクタ
  connect() {
    const accordionTriggers = document.querySelectorAll('.js-accordion-trigger');
    accordionTriggers.forEach(trigger => trigger.addEventListener('click', this.toggleAccordion));
  }

  // デストラクタ
  disconnect() {
    const accordionTriggers = document.querySelectorAll('.js-accordion-trigger');
    accordionTriggers.forEach(trigger => trigger.removeEventListener('click', this.toggleAccordion));
  }

  toggleAccordion() {
    const target = this.parentNode;
    target.classList.toggle('is-open');
  }
}
