import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="shop--carts--payment"
export default class extends Controller {

  static targets = [
  ]
  beforeOptionPrices = []

  connect() {
    this.setInitialState()
  }

  setInitialState() {
    this.beforeOptionPrices = JSON.parse(document.getElementById('before-option-prices').dataset.beforeOptionPrices) || []
    this.calcOrderPrice()
    this.toggleRequred()
    this.changeStatus()
  }

  changeStatus() {
    const orderStatusElm = document.getElementById('flyer_order_order_status');
    const orderStatusValue = orderStatusElm.options[orderStatusElm.selectedIndex].value;

    document.getElementById('update_button').addEventListener('click', function(event) {
      const orderStatusElm = document.getElementById('flyer_order_order_status');
      const clickedOrderStatusValue = orderStatusElm.options[orderStatusElm.selectedIndex].value;
      if (orderStatusValue != clickedOrderStatusValue) {
        if (!confirm('ステータスが変更されています。更新しますか？')) {
          event.preventDefault();
        }
      }
    });
  }

  // オプションの値をセットするイベントの取得(前回注文より)
  setBeforeOrderOptions(event) {
    event.preventDefault();
    if (this.beforeOptionPrices.length <= 0) {
      alert('過去の注文内容がありません')
      return
    }

    const confirmed = confirm('注文内容を過去の注文データから取得しますか？');
    if (confirmed) {
      const targetElements = document.querySelectorAll(".option-price-target")
      targetElements.forEach((elem, index) => {
        this.setOption(elem)
      })
    }
  }
  // オプションの値をセットする(前回注文より)
  setOption(elem) {
    const id = elem.querySelector("#option_prices__id")
    const option_id = elem.querySelector("#option_prices__option_id")
    const name = elem.querySelector("#option_prices__name")
    const quantity = elem.querySelector("#option_prices__quantity")
    const unit_price = elem.querySelector("#option_prices__unit_price")
    const beforeOptionPrice = this.beforeOptionPrices.find((i) => { return Number(i.option_id) === Number(option_id.value) })
    if(beforeOptionPrice) {
      name.value = beforeOptionPrice.name
      quantity.value = beforeOptionPrice.quantity
      unit_price.value = beforeOptionPrice.unit_price
    }
    this.calcOrderPrice()
  }

  calcOrderPrice() {
    const order_price = parseInt(document.getElementById('order-price').textContent.replace(/,/g, ''))
    const total_price_elem = document.getElementById('total-price')
    const sum_option_price_elem = document.getElementById('sum-option-price')
    const discount_price_elem = document.getElementById('discount-price')
    const insert_price = Number(document.getElementById('stock_insert_price')?.value || 0)


    let sum_total_price = order_price
    let sum_option_price = 0
    let discount_price = 0


    const targetElements = document.querySelectorAll(".option-price-target")
    targetElements.forEach((elem, index) => {
      const option_id = elem.querySelector("#option_prices__option_id").value
      const quantity = elem.querySelector("#option_prices__quantity").value
      const unit_price = elem.querySelector("#option_prices__unit_price").value
      if(quantity && unit_price) {
        if(Number(option_id) === 13) {
          discount_price = Math.floor(quantity * unit_price)
        } else {
          sum_option_price += Math.floor(quantity * unit_price)
        }
      }
    })
    sum_option_price_elem.textContent = sum_option_price.toLocaleString()
    discount_price_elem.textContent = discount_price.toLocaleString()
    total_price_elem.textContent = (sum_total_price + insert_price + sum_option_price - discount_price).toLocaleString()
  }

  toggleRequred() {
    document.querySelectorAll('.option-price-target').forEach(function(target) {
      target.querySelectorAll('input:not([type="hidden"])').forEach(function(input) {
        input.addEventListener('change', function() {
          var hasValue = false;
          target.querySelectorAll('input:not([type="hidden"])').forEach(function(input) {
            if (!!input.value) {
              hasValue = true;
            }
          });
          target.querySelectorAll('input:not([type="hidden"])').forEach(function(input) {
            input.required = hasValue;
          });
        });
      });
    });
  }
}
